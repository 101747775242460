<template>

    <form>
        <div class="card">
            <div class="card-body">
                <div class="row justify-content-center">
                    <div class="col-12 col-md-6">
                        <FormElementTextInput
                            label="First Name"
                            :content="formContact.first_name"
                            :is-required="true"
                            @update="(value) => formContact.first_name = value"
                        />
                    </div>
                    <div class="col-12 col-md-6">
                        <FormElementTextInput
                            label="Last Name"
                            :content="formContact.last_name"
                            :is-required="true"
                            @update="(value) => formContact.last_name = value"
                        />
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-12 col-md-4">
                        <FormElementTextInput
                            label="Email Address"
                            :content="formContact.email"
                            :is-required="false"
                            @update="(value) => formContact.email = value"
                        />
                    </div>
                    <div class="col-12 col-md-4">
                        <FormElementTextInput
                            label="Phone"
                            :content="formContact.phone"
                            :is-required="false"
                            @update="(value) => formContact.phone = value"
                        />
                    </div>
                    <div class="col-12 col-md-4">
                        <FormElementTextInput
                            label="Mobile"
                            :content="formContact.mobile"
                            :is-required="false"
                            @update="(value) => formContact.mobile = value"
                        />
                    </div>
                </div>
            </div>
        </div>

        <div class="row justify-content-end">

            <div class="col-auto" v-if="showCancelButton">
                <button class="btn btn-light" @click="emits('cancel')">Cancel</button>
            </div>

           <template v-if="creatingNewContact">
               <div class="col-auto">
                   <button type="button" @click="handleSubmit" class="btn btn-primary">
                       <Icon icon="save" class="me-2 inline" />Save
                   </button>
               </div>
           </template>
            <template v-else>
                <div class="col-auto">
                    <button type="button" @click="handleUpdate" class="btn btn-primary">
                        <Icon icon="save" class="me-2 inline" />Save
                    </button>
                </div>
            </template>

        </div>
    </form>
</template>

<script setup lang="ts">

import {useVuelidate} from "@vuelidate/core";
const v$ = useVuelidate();
const toasts = useToastsStore();

const initialContactState: IContact = {
    salutation: '',
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    mobile: '',
    title: '',
    photo: '',
    active: false,
}

interface IContactResponse {
    message: string,
    contact: IContact
}

const props = withDefaults(defineProps<{
    accountId: string
    contact?: IContact
    creatingNewContact?: boolean
    resetForm?: boolean
    showing?: boolean
    showCancelButton?: boolean
}>(), {
    contact: null,
    creatingNewContact: true,
    resetForm: false,
    showing: true,
    showCancelButton: false
});

const emits = defineEmits<{
    (event: 'submit', value: IContact)
    (emit: 'formIsReset', value: boolean)
    (event: 'cancel')
}>()

const formContact: IContact = props.contact
? ref({...props.contact})
: reactive<IContact>({...initialContactState});

watch(() => props.resetForm, (reset: boolean) => {
    if(!reset){
        return;
    }

    if(props.creatingNewContact){
        formContact.value = Object.assign(formContact, initialContactState)
    }

    if(!props.creatingNewContact){
        formContact.value = {...props.contact}
    }

    v$.value.$reset()

    emits('formIsReset', reset)
})

const formIsValid = (): boolean => {
    if(v$.value.$invalid) {
        toasts.validationError('Check all form fields are completed correctly');
        v$.value.$touch();
        return false;
    }
    return true;
}

const handleSubmit = async () => {
    if(formIsValid()){
        const { data: saveResponse }: IContactResponse  = await useApiFetch(`/api/accounts/${props.accountId}/contacts`, {
            method: 'post',
            body: formContact
        });

        if (saveResponse.value) {
            emits('submit', saveResponse.value.contact)
        }
    }
}
const handleUpdate = async () => {
    if(formIsValid()){
        const { data: updateResponse, error }: IContactResponse = await useApiFetch(`api/accounts/${props.accountId}/contacts/${props.contact.id}`, {
            method: 'put',
            body: formContact.value
        });

        if(updateResponse.value) {
            emits('submit', updateResponse.value.contact)
        }
    }
}
</script>
